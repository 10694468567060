import React from 'react';
import PropTypes from 'prop-types';
import HeaderEvents from '@client/ClientEvents/plus24/Header.jsx';
import FooterEvents from '@client/ClientEvents/plus24/Footer.jsx';
import ClientPayEvents from '@client/ClientEvents/ClientPayEvents.jsx';
import { AppContextComponent } from '@client/ClientEvents/Common/AppContextComponent.jsx';

const Plus24Events = ({config}) => {
  const { header, footer } = config;
  return (
    <React.Fragment>
      <AppContextComponent config={config}>
        <ClientPayEvents />
        {footer ? <FooterEvents /> : null}
        {header ? <HeaderEvents /> : null}
      </AppContextComponent>
    </React.Fragment>
  );
};

Plus24Events.propTypes = {
  config: PropTypes.shape({
    header: PropTypes.bool.isRequired,
    footer: PropTypes.bool.isRequired,
    logged: PropTypes.bool.isRequired,
    website: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    device: PropTypes.string.isRequired,
  }).isRequired,
};

export default Plus24Events;
