import React from 'react';
import PropTypes from 'prop-types';


const HeaderPayEvents = ({ pay }) => {
  const abbonatiButtons = document.querySelectorAll('li.abbonati-button');
  if (pay && typeof abbonatiButtons !== 'undefined' && abbonatiButtons.length) {
    for (let i = 0; i < abbonatiButtons.length; i += 1) {
      if (typeof abbonatiButtons[i].style !== 'undefined' && typeof abbonatiButtons[i].style.cssText !== 'undefined') {
        abbonatiButtons[i].style.cssText = 'display:none !important';
      } else {
        abbonatiButtons[i].style = 'display:none !important';
      }
    }
  }
  return null;
};

HeaderPayEvents.propTypes = {
  pay: PropTypes.bool.isRequired,
}

export default HeaderPayEvents;
