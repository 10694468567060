import React from 'react';
import ReactDOM from 'react-dom';
import { UserButtonAnon, LogoutButtonFooter } from '@client/ClientEvents/plus24/UserButton.jsx';

const FooterEvents = () => {

  if (document.querySelector("#user_button_logout_footer"))
    return ReactDOM.createPortal(
      <LogoutButtonFooter />,
      document.querySelector('#user_button_logout_footer')
    );
  if (document.querySelector("#user_button_anon_footer"))
    return ReactDOM.createPortal(
      <UserButtonAnon />,
      document.querySelector('#user_button_anon_footer')
    );
  return null;
  }


export default FooterEvents;