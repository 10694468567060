import React from 'react';
import PropTypes from 'prop-types';

import HeaderPayEvents from '@client/ClientEvents/plus24/HeaderPay.jsx';

const Plus24PayEvents = ({ config, me }) => {
  const { header, footer } = config;
  return <React.Fragment>{header ? <HeaderPayEvents config={config} pay={me.pay} /> : null}</React.Fragment>;
};

Plus24PayEvents.propTypes = {
  config: PropTypes.objectOf(PropTypes.string).isRequired,
  me: PropTypes.objectOf(PropTypes.string).isRequired,
}
export default Plus24PayEvents;
