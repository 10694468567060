import React from 'react';
import PropTypes from 'prop-types';

import { cleanup } from '@client/ClientEvents/plus24/utils.jsx';
import { modalVisibility } from '@client/ClientEvents/plus24/Modal.jsx';
import { duHost } from '@client/ClientEvents/plus24/utils.jsx';

function UserButton({ friendlyname }) {
  const handler = (e) => {
    const prefix = e.currentTarget.parentNode.getAttribute('id').indexOf('-sticky') > -1 ? '.sticky-header ' : '.main-header ';
    cleanup('user-open');
    document.querySelector(`${prefix}.huser.dropdown`).classList.toggle('is_expanded');
    document.querySelector(`${prefix}.dropdown-menu`).classList.toggle('show');
  };
  return (
    <button className="btn btn--huser btn--dropdown" onClick={handler} type="button" aria-label="accesso effettuato">
      <span className="btn-label" aria-hidden="true">{friendlyname}</span>
      <span className="icon icon-chevron-down d-none d-lg-block"/>
      <span className="icon icon--multi icon-user-logged d-lg-none">
        <span className="path1" />
        <span className="path2" />
      </span>
    </button>
  );
}

export const UserButtonAnon = () => {
  const handler = (e) => {
    cleanup('modal-open');
    modalVisibility('show', 'block');
  };
  return (
    <button className="btn btn--huser" data-target="#s24-modal-login" data-toggle="modal" onClick={handler} type="button" aria-label="accedi" tabIndex="0">
      <span className="btn-label" aria-hidden="true">Accedi</span>
      <span className="icon icon-user" />
    </button>
  );
};

export const LogoutButtonHeader = () => (
  <a className="link-icon link-icon--big" href={`${duHost}/du/authfiles/logoff.aspx?RURL=${document.location}`}>
    <span className="icon icon-logout"/>
    Log-out
  </a>
);

export const LogoutButtonFooter = () => (
  <a className="btn btn--huser btn--logout" href={`${duHost}/du/authfiles/logoff.aspx?RURL=${document.location}`}>
    <span className="icon icon-logout" />
    Log-out
  </a>
);


UserButton.propTypes = {
  friendlyname: PropTypes.string.isRequired,
}

export default UserButton;
