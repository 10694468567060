import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ModalLogin, { modalVisibility } from '@client/ClientEvents/sole24ore/Modal.jsx';
import { UserButtonAnon } from '@client/ClientEvents/sole24ore/UserButton.jsx';
import { appendBackdrop } from '@client/ClientEvents/Common/index.jsx';

const LoginEvents = () => {
  

  useEffect(() => {
    if (document.querySelectorAll('.modal-backdrop').length === 0) {
      appendBackdrop('modal-backdrop', 'z-index:2147483645!important');
    } else {
      document.querySelector('.modal-backdrop').style.cssText += 'z-index:2147483645!important';
    }
    document.querySelector('#s24-modal-login').style.cssText += 'z-index:2147483647!important';
    // forzo il click sul bottone nascosto
    document.querySelector('#cmp_user_button_anon > button').click()
  })
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<ModalLogin />, document.querySelector('#modal_login_placeholder'))}
      {ReactDOM.createPortal(<UserButtonAnon newDesign isInvisible />, document.querySelector('#cmp_user_button_anon'))}
    </React.Fragment>
  );
};

export default LoginEvents;
