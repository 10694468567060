import React, { useEffect } from 'react';
import { loadScript, meteoUrl } from '@client/ClientEvents/Common/index.jsx';

const Meteo = () => {
  useEffect(() => {
    loadScript(meteoUrl, 'meteo', () => {
      // eslint-disable-next-line no-undef
      const myComponent = new ScriptWeather('.weather_ilmeteo');
      myComponent.render();
    });
  }, []);

  return <span className="weather_ilmeteo hicon-label hicon-label--t clientside"  />
  ;
};

export default Meteo;
