import React, { useContext, useEffect } from 'react';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';
import { plus24Domain, duHost, socialLogin, soleHost, websites, getDefaultSitecode } from '@client/ClientEvents/Common/index.jsx';

const ModalLogin = () => {
  const config = useContext(AppContext);
  // const returnURL = document.location.href.indexOf('?') === -1 ? document.location.href : document.location.href.split('?')[0];
  const returnURL =
    document.location.href.indexOf('?') === -1
      ? document.location.href
      : `${document.location.href.split("?")[0]}?${document.location.search
          .slice(1)
          .split('&')
          .filter((stringParam) => {
            if (stringParam.split('=')[0].toLowerCase() !== 'errmsg') {
              return true;
            }
            return false;
          })
          .join('&')}`;
  const { website, device, errMsg, sc } = config;
  const resetPwd = `${duHost}/utenti/passwordReset.aspx?RURL=${returnURL}`;
  const signup = `${duHost}/utenti/Registrazione.aspx?schema=Reg2013&Pers=home2013&Newsletters=DURegCO&sc_motivo=N24%3aspontaneo&SiteCode=${sc}&RURL=${returnURL}`;
  let logo = '';

  switch (website) {
    case websites.SOLE_WEB:
      logo = <img className="modal-logo--sole" src={`${soleHost}/static/img/ilsole24ore-o.svg`} alt="Il Sole 24 Ore" />;
      break;
    case websites.PLUS24:
      logo = <img className="modal-logo--24plus" src={`${plus24Domain}/img/24plus.svg`} alt="24+" />;
      break;
    default:
      logo = <img className="modal-logo--sole" src={`${soleHost}/static/img/ilsole24ore-o.svg`} alt="Il Sole 24 Ore" />;
  }

  const closeHandler = (e) => {
    modalVisibility('hide', 'none');
  };
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [remember, setRemember] = React.useState(true);
  const styleOverflow = device === 'mobile' ? { overflowY: 'scroll' } : {};

  return (
    <React.Fragment>
      <div id="s24-modal-login" className="modal" tabIndex="-1" role="dialog" style={styleOverflow} data-ismobile={device === 'mobile'}>
        <div className="modal-dialog modal-dialog-centered modal-dialog--sans modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-logo">{logo}</span>
              <button type="button" className="ltool ltool--circle modal-close" data-dismiss="modal" aria-label="Close" onClick={closeHandler}>
                <span className="icon icon-close" />
              </button>
            </div>

            <div className="modal-body">
              <h3 className="modal-title">Benvenuto</h3>
              <p className="modal-text">Inserisci username e password per accedere ai servizi del Sole 24 Ore</p>

              <form action={`${duHost}/du/authfiles/logincentrale.aspx`} method="post" name="authUser" className="modal-form">
                <input value="" name="URL" type="hidden" />
                <input value="BYPOST" name="realSubmit" type="hidden" />
                <input type="hidden" name="RURL" value={`${returnURL}`} />
                <input type="hidden" name="ERRURL" value={`${returnURL}`} />
                <input type="hidden" name="SC" value={sc} />
                <div className="form-group">
                  <label className="label" htmlFor="login-username">
                    Username o email
                  </label>
                  <input className="input focusOnOpen" type="text" id="login-username" placeholder="" name="txtUsername" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>

                <div className="form-group">
                  <label className="label" htmlFor="login-password">
                    Password
                  </label>
                  <input className="input" type="password" id="login-password" placeholder="" name="txtPassword" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <a className="label abs-tr" href={resetPwd}>
                    Recupera password
                  </a>
                </div>

                <div className="form-group d-flex align-items-center">
                  <div className="checkbox">
                    <label className="checkbox-wtext">
                      <input
                        type="checkbox"
                        value={remember ? '1' : '0'}
                        checked={remember}
                        onChange={(e) => {
                          setRemember(e.target.checked);
                        }}
                        name="RememberMe"
                      />
                      <span className="icon icon-check" />
                      Ricordami
                    </label>
                  </div>

                  <button type="submit" className="btn btn--big btn--blue ml-auto" tabIndex="0">
                    Accedi
                  </button>
                </div>
              </form>
              {errMsg != '' ? (
                <p className="modal-text text-center" style={{ color: '#d41b17', fontSize: '1rem' }}>
                  {errMsg}
                </p>
              ) : null}
              <p className="modal-text text-center">
                Non hai un account?
                <a href={signup}> Registrati ora</a>
              </p>
            </div>

            <div className="modal-footer">
              <div className="modal-soclogin" id="socialLogin">
                <p className="modal-text modal-soclogin-text">Puoi accedere anche con</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const modalVisibility = (action, styleVal, modalSel = '#s24-modal-login', backdropSel = '.modal-backdrop') => {
  const { body } = document;
  // se non c'è il backdrop lo inserisco al volo....
  if (document.querySelectorAll(backdropSel).length === 0) {
    const backdrop = document.createElement("div");
    backdrop.classList.add(backdropSel.replace(".", "").replace("#",""));
    document.querySelector(modalSel).parentNode.after(backdrop)
  }
  if (action === 'show') {
    document.querySelector(modalSel).classList.add('show');
    document.querySelector(backdropSel).classList.add('show');
    body.classList.add('modal-open');
    if (modalSel === '#s24-modal-login') {
      socialLogin();
    }
  } else {
    document.querySelector(modalSel).classList.remove('show');
    document.querySelector(backdropSel).classList.remove('show');
    if (document.querySelectorAll('#modal-pay').length === 0) body.classList.remove('modal-open');
  }
  document.querySelector(modalSel).style.display = styleVal;
  document.querySelector(backdropSel).style.display = styleVal;

  // ACCESSIBILITÀ: gestione del focus in entrata e uscita dalle modali
  if (modalSel === '#s24-modal-login' || modalSel === '#modal-search') {
    // accessibilità: quando apri la modale il primo input deve avere il focus, quando la chiudi torna al pulsante che ha chiamato
    // per dare il focus devo aspettare che la modale sia visibile, quindi dopo aver applicato gli stili
    const focusOnOpenItems = document.getElementsByClassName('focusOnOpen');
    const focusOnCloseItems = document.getElementsByClassName('focusOnClose');
    if (action === 'show') {
      Array.prototype.forEach.call(focusOnOpenItems, (item) => {
        if (item.offsetParent !== null) {
          item.focus()
        }
      })
    } else {
      Array.prototype.forEach.call(focusOnCloseItems, (item) => {
        if (item.offsetParent !== null) {
          item.focus()
        }
      })
    }
  }
};

export const showLoginOverPay = (zIndex = '1095') => {
  modalVisibility('show', 'block');
  document.querySelector('#modal_login_placeholder').style.zIndex = '1095';
  document.querySelector('#modal_login_placeholder').style.position = 'fixed';
  document.querySelector('.modal-backdrop').style.zIndex = '1094';
};

export default ModalLogin;
