import { plus24Domain, duHost, socialLogin, loadScript } from '@client/ClientEvents/Common/index.jsx';

export const cleanup = (action) => {
  const { body } = document;
  if (action !== 'menu-open') body.classList.remove('is-menu-open');
  if (action !== 'seach-open') body.classList.remove('is-search-open');
  if (action !== 'user-open' && document.querySelector('.huser.dropdown')) {
    document.querySelector('.huser.dropdown').classList.remove('is_expanded');
    document.querySelector('#user_dropdown').classList.remove('show');
  }
};

export { plus24Domain, duHost, socialLogin, loadScript };
