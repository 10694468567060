import React, { useContext, useEffect, useState } from 'react';
import { promiseTimeout, duHost } from '@client/ClientEvents/Common/index.jsx';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';
import { showLoginOverPay } from '@client/ClientEvents/Common/Modal.jsx';

const ModalLockSole = () => {
  const [modalHtml, setModalHtml] = useState('');
  const config = useContext(AppContext);
  const { logged, product, device, errMsg, useInlineLock } = config;

  const getPaywallModal = async () => {
    await promiseTimeout(
      2500,
      fetch(`${process.env.hostDomain}/api/paywall?website=sole24ore&logged=${logged}&product=${product}&device=${device}&isModal=true`)
        .then((res) => res.text())
        .then((res) => {
          setModalHtml(res);
          if (!useInlineLock) {
            document.body.classList.add('modal-open');
          }
        })
    );
  };
  useEffect(() => {
    getPaywallModal(logged);
  }, []);

  useEffect(() => {
    if (document.getElementById('loginButton')) {
      document.getElementById('loginButton').addEventListener('click', showLoginOverPay);
      if (errMsg !== '') showLoginOverPay();
    }
    if (document.getElementById('logoutButton')) {
      const url = `${duHost}/du/authfiles/logoff.aspx?RURL=${document.location}`
      document.getElementById('logoutButton').addEventListener('click', () => {document.location = url})
    }
    return function cleanup() {
      if (document.getElementById('loginButton')) {
        document.getElementById('loginButton').removeEventListener('click', showLoginOverPay);
      }
      if (document.getElementById('logoutButton')) {
        document.getElementById('logoutButton').removeEventListener('click',  () => {document.location = url});
      }
    };
  }, [modalHtml]);
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: modalHtml }} />;

  /* return (
    <div className="modal show" tabIndex="-1" role="dialog" id="modal-pay" >
      <div className="modal-dialog modal-dialog-centered modal-dialog--sans modal-md">
        <div className="modal-content text-center">
          <div className="modal-header">
            <div className="lock-head">
              <h3 className="modal-title">Vuoi acquistare questo contenuto?</h3>
              <p className="modal-text lock-text">Siamo spiacenti, al momento non sono disponibili offerte</p>
            </div>
          </div>

          <div className="modal-body">
            <div className="lock-offer lock-offer--single">
              <button className="btn btn--blue btn--full" type="button">
                Richiedi al servizio clienti
              </button>
            </div>
          </div>

          <div className="modal-footer lock-foot">
            <div className="lock-foot-action">
              <span className="lock-label">Sei già abbonato?</span>
              <a className="btn btn--big" href="#">
                Accedi
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  ); */
};

export default ModalLockSole;
