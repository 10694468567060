import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderEvents from '@client/ClientEvents/sole24ore/Header.jsx';
import FooterEvents from '@client/ClientEvents/sole24ore/Footer.jsx';
import LoginEvents from '@client/ClientEvents/sole24ore/Login.jsx';
import ClientPayEvents from '@client/ClientEvents/ClientPayEvents.jsx';
import { AppContextComponent } from '@client/ClientEvents/Common/AppContextComponent.jsx';

const SoleEvents = ({ config }) => {
  const { header, footer, login } = config;

  useEffect(() => {
    let event;
    if (typeof Event === 'function') {
      event = new Event('chf_loaded');
    } else {
      event = document.createEvent('Event');
      event.initEvent('chf_loaded', true, true);
    }

    document.body.dispatchEvent(event);
  });
  return (
    <React.Fragment>
      <AppContextComponent config={config}>
        {!login && <ClientPayEvents />}
        {!login && footer ? <FooterEvents /> : null}
        {!login && header ? <HeaderEvents /> : null}
        {login ? <LoginEvents /> : null}
      </AppContextComponent>
    </React.Fragment>
  );
};

SoleEvents.propTypes = {
  config: PropTypes.shape({
    header: PropTypes.bool.isRequired,
    footer: PropTypes.bool.isRequired,
    logged: PropTypes.bool.isRequired,
    website: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    device: PropTypes.string.isRequired,
  }).isRequired,
};

export default SoleEvents;
