/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom';
import { SoleEvents, Plus24Events, UnmountPortals } from '@client/ClientEvents/index.jsx';

import { getDeviceType, createNode } from '@client/ClientEvents/Common/index.jsx';
import fetch from 'isomorphic-fetch';

import { userIsLogged, getUserData } from '../../utils/NileCookieManager';
import { getWordsFirstLetter } from '../../utils/common.js';
import { isUnionType } from 'graphql';

const defaultConfig = {
  website: 'sole24ore', // sole24ore, plus24
  section: 'homepage', // homepage, section, article
  type: 'xs', // ancora non in uso
  footer: true, // true | false
  header: true, // true | false
  login: false, // true | false - per avere in pagina solo la login e il backdrop
  hotTrends: true, // true | false
  placeholders: ['s24_header_footer', 'modal_login_placeholder', 'modal_search_placeholder', 'modal_lock'],
  rootName: 's24_header_footer', // deve essere presente anche in placeholders
  autoCss: true,
  useInlineLock: false, // quando la pagina host vuole usare la funzione esposta isUserAuthorized per inserire la modale in pagina
  inlineLockTarget: 's24_inlinelock',
  isLocked: false, // contenuto protetto devo controllare autorizzazioni; was: checkAuth
  template: 'normal', // normal || large -> normal è il css con breakpoint 1440, large con bp 1540. 
  withHamburger: true, // opzione in caso vogliamo spegnere il menù per alleggerire la testata. Solo header Sole
  newDesign: false,
  withMeteo: true, // per usare il servizio meteo. Siccome i cookie settati dal fornitore non comprendono il terzo livello, ci protrebbero essere discrepanze nella città rispetto a quello che si vede per esempio sul .com
  refreshEnabled: false,
  customSubsections: [], // lista subsection alternativa nel caso la si voglia personalizzare invece che usare quella del .com. formato: {label: "", link: ""}. Viene inserita lato client, e lato server viene lasciato libero lo spazio con id oppurtuno nel caso sia compilata.
  scrollUp: '', // nome dell'id verso cui scrollare dal footer, link torna su
  partner: "",
  cobrandPrivacy: false,
  shouldShowCta: true,
  sc: 'CO',
};

const filterParams = ['isLocked', 'sectionLink', 'subSectionLink', 'customSubsections', 'useInlineLock', 'inlineLockTarget'];

const toParams = (json) => {
  return Object.keys(json)
    // il parametro isLocked al client non serve per adesso. nel caso volessimo cachare su varnish, potrebbe duplicare le chiavi di cache. stesso discorso per use inline lock
    .filter((f) => !filterParams.includes(f) )
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
    })
    .join('&');
};

const getUserInfo = () => {
  const userData = getUserData();
  return {
    logged: userIsLogged(),
    friendlyname: (userData && userData.friendlyname) || '',
    shortname: userData.shortname || getWordsFirstLetter(userData.friendlyname) || 'NC',
    pay: userData.pay,
  };
};

const addConfigParam = ({ config, param }) => {
  return {
    ...config,
    ...param,
  };
};

const addClientEvents = (config) => {
  // a seconda della configurazione chiamo la funzione che gestisce gli eventi.
  const { website, rootName, login } = config;
  switch (website) {
    case 'plus24':
      ReactDOM.render(<Plus24Events config={config} />, document.getElementById(rootName));
      break;
    case 'sole24ore':
      if (login) {
        ReactDOM.render(<SoleEvents config={config} />, document.getElementById('cmp_user_button_anon'));
      } else {
        ReactDOM.render(<SoleEvents config={config} />, document.getElementById(rootName));
      }
      break;
    default:
      ReactDOM.render(<SoleEvents config={config} />, document.getElementById(rootName));
  }
};

let originalConfig = {
    
}
const setConf = (config) => {
  originalConfig = Object.assign(config);
}

const getConf = () => {
  return originalConfig;
}

const removeSkeleton = () => {
  const skeleton = document.querySelector("#skeleton");
  if(skeleton) skeleton.parentNode.removeChild(skeleton);
}

const getHeaderFooter = (updatedConfig) => {
  document.body.dataset.chfLoaded = "true";
  
  const errMsg = document.location.search.slice(1).split("&").map((stringParam) => {
    if (stringParam.split("=")[0].toLowerCase() === 'errmsg') {
      return stringParam.split("=")[1];
    }
    return '';
  }).join('').replace(/\+/gi, ' ');
  const userConfig = updatedConfig || _s24_config;
  const temp_config = Object.assign(defaultConfig, userConfig, getUserInfo(), {
    device: getDeviceType(navigator.userAgent),
    // newDesign: _s24_config.section === 'section' ? false : _s24_config.newDesign // la sezione non ha il nuovo design, per cui forzo il valore di newDesign a false per evitare problemi
    errMsg,
  });
  const config = Object.assign(temp_config, {
    header: temp_config.header && document.querySelectorAll("#s24_header").length > 0, // se l'utente non ha cambiato il valore di header nella config ma non ha messo il div mettiamo false per evitare errori react con il portal
    footer: temp_config.footer && document.querySelectorAll("#s24_footer").length > 0, // idem per footer
  
  })
  const { header, footer, login, placeholders, autoCss, template, withHamburger, newDesign } = config;
  
  // siccome permetto l'aggiornamento della config al client, i parametri che non possono essere cambiati (per esempio il fatto che sia pay) li tengo da parte.
  setConf(config)
  // prima di lanciare le fetch controllo se in pagina ci sono tutti i nostri div "placeholder": root,
  if (login && !document.getElementById("cmp_user_button_anon")) { // lo faccio sempre nuovo perché altrimenti react si arrabbia se cerco di fare un portale dentro un pulsante che già è presente dal primo render
    createNode('modal_login_placeholder', '', ''); // se voglio solo la login faccio solo quel placeholder, tutti gli altri non servono
    // inietto il codice che ospiterà il bottone di login nascosto. 
    const loginEl = document.createElement('div');
    loginEl.setAttribute('id', 'cmp_user_button_anon');
    document.body.appendChild(loginEl);
  }
  if(!login){
    placeholders.forEach((idName) => {
      if (idName === "modal_lock") createNode(`${idName}_${Date.now().toString()}`, "modal_lock lock lock-s24 lock-s24plus d-none"); // anche la classe solo per modal lock perché ha id randomico
      else createNode(idName)
    });
  }
  const serverParams = addConfigParam({ config: userConfig, param: { logged: config.logged, device:config.device, template, withHamburger, newDesign, hasCustomSubsections: config.customSubsections.length > 0, partner: config.partner, cobrandPrivacy: config.cobrandPrivacy } });
  const targets = [];
  const fetchList = [];
  
  if (!document.getElementById("chfCSS")) { // nel caso di sola login potremmo voler riaprire tutto, ci evitiamo delle get inutili
    if (autoCss) {
      targets.push('css');
      fetchList.push(fetch(`${process.env.hostDomain}/api/css?${toParams(serverParams)}`));
    }
  }
  if (!login && header) { // se mi chiedi la login automaticamente non ti servo la testata.
    targets.push('s24_header');
    fetchList.push(fetch(`${process.env.hostDomain}/api/header?${toParams(serverParams)}`));
  }
  if (!login && footer) {
    targets.push('s24_footer');
    fetchList.push(fetch(`${process.env.hostDomain}/api/footer?${toParams(serverParams)}`));
  }

  
  Promise.all(fetchList)
    .then((responses) => {
      return Promise.all(
        responses.map((response) => {
          return response.text();
        })
      );
    })
    .then((data) => {
      // carico prima i css altrimenti la testata si vede prima sformattata
      data.forEach((d, i) => {
        let target = document.getElementById(targets[i]);
        if (targets[i] !== 'css') {
          setTimeout(() => {
            if (target) {
              target.innerHTML = d;
              removeSkeleton();
            }
          }, 500);
        } else {
          /* qualcuno potrebbe strippare gli id su head, quindi solo nel caso del css faccio un document.getElementsByTagName */
          target = document.getElementsByTagName('head')[0];
          if (target) {
            target.innerHTML += d;
          }
        }
      });
      setTimeout(() => {
        if (window && document.location.hash === '') {
          window.scrollTo(0, 0);
        }
        addClientEvents(config);
      }, 500);
    })
    .catch((e) => console.error(e));
};




document.addEventListener('DOMContentLoaded', function () {
  if (document.body.dataset.chfLoaded !== "true") {
    getHeaderFooter()
  }
});



window.refreshCHF = () => {
  document.body.dataset.chfLoaded = "false"; // fermo il flag 
  const config = getConf();
  const { refreshEnabled, isLocked, useInlineLock, product} = config;
  const updatedConfig = {
    ...config,
    ..._s24_config,
    refreshEnabled,
    isLocked,
    useInlineLock,
    product
  }
  // FIXME: PAY NON MODIFICABILE
  if(refreshEnabled) {
    // UnmountPortals();
    //ReactDOM.unmountComponentAtNode(document.querySelector('.weather_ilmeteo'))
    //document.querySelector(".weather_ilmeteo").innerText = '';
    getHeaderFooter(updatedConfig);
  }
}

window.customLoadCHF = (config) => {
  const { login } = config || false;
  if (login || document.body.dataset.chfLoaded !== "true") {
    getHeaderFooter(config);
  }
}