import React, { useEffect, useContext } from 'react';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';

const FooterEvents = () => {
  const config = useContext(AppContext);
  const { scrollUp } = config;
  useEffect(() => {
    document.getElementById('scrollup').setAttribute('href', `#${scrollUp}`);
  });
  return <span/>;
};

export default FooterEvents;
