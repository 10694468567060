import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalLock from '@client/ClientEvents/Common/ModalLock.jsx';
import { modalVisibility } from '@client/ClientEvents/Common/Modal.jsx';
import { appendBackdrop } from '@client/ClientEvents/Common/index.jsx';

const NoTampering = ({ domIsChanging, setDomIsChanging }) => {
  let originalNode = ''; 
  let originalBackdrop = '';
  const backdropStyle = 'display:block;width:100vw; height:100vh;background:black;opacity:0.7;position:fixed;top:0;left:0;z-index:1021;';
  const callback = (mutationsList) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const mutation of mutationsList) {
      // visto che qualcuno ha cambiato qualcosa, butto la vecchia modale e la ricarico
      const { target, removedNodes } = mutation;
      if (!originalNode) return;
      if (removedNodes.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const node of removedNodes) {
          if (node.isSameNode(originalNode)) {
            setDomIsChanging(domIsChanging + 1);
            break;
          }
          if (node.isSameNode(originalBackdrop)) {
            originalNode.parentNode.removeChild(originalNode);
            break;
          }
        }
      }
      if (target.isSameNode(originalNode)) {
        originalNode.parentNode.removeChild(originalNode);
      }
      if (target.isSameNode(originalBackdrop)) {
        originalBackdrop.parentNode.removeChild(originalBackdrop);
      }
    }
  };

  React.useEffect(() => {
    if (document.querySelectorAll('.modal-pay-backdrop').length === 0) appendBackdrop('modal-pay-backdrop');
    document.querySelectorAll('.modal-pay-backdrop')[0].style.cssText = backdropStyle;
    if (document.querySelectorAll('#modal-pay').length > 0) modalVisibility('show', 'block', '#modal-pay', '.modal-pay-backdrop');
    originalNode = document.getElementsByClassName('modal_lock')[0];
    originalBackdrop = document.getElementsByClassName('modal-pay-backdrop')[0];

    const whatToWatch = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    observer.observe(document.body, whatToWatch);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <ModalLock key={Date.now()} />;
};

NoTampering.propTypes = {
  domIsChanging: PropTypes.number.isRequired,
  setDomIsChanging: PropTypes.func.isRequired,

};

export default NoTampering;
