import React from 'react';
import 'element-closest-polyfill';

function getFocusableElementList() {
  return 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
}

function modalTrap(element, e) {
  const focusableElements = element.querySelectorAll(getFocusableElementList());
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];
  const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusableElement) {
    firstFocusableElement.focus();
    e.preventDefault();
  }
}

const SidemenuBackdrop = () => {
  const handler = (e) => {
    e.preventDefault();
    document.body.classList.toggle('is-sidemenu-in');
  };
  return <span className="sidemenu-backdrop" onClick={handler} />;
};

const SidemenuCloseButton = () => {
  const handler = (e, click) => {
    if (click || e.key === 'Enter') {
      e.preventDefault();
      document.body.classList.remove('is-sidemenu-in');
      const hamburgerContainer = document.getElementById('header-hamburger');
      if (hamburgerContainer) {
        const button = hamburgerContainer.querySelector('button');
        if (button) {
          button.focus();
        }
      }
    }
  };
  return <span className="icon icon-close" onClick={(e) => handler(e, true)} onKeyDown={(e) => handler(e, false)} role="button" tabIndex="0" aria-label="Chiudi" />;
};

const SidemenuVoce = () => {
  let timer = null;

  const handleEvent = (e) => {
    const sidemenuMaxLevel = 4;

    // il target può essere qualsiasi elemento all'interno del link quindi bisognerà trovare il link stesso
    const { target } = e;

    const backButton = target.classList.contains('sidemenu-back') || target.classList.contains('icon-arrow-left');

    /* 
          Se è stato premuto il pulsante back: 

          - prendo il link che è stato premuto che ha la classe sidemenu-back

          Se è stato premuto un link: 
          - prendo il link premuto con classe sidemenu-link

    */
    const targetAnchor = backButton ? target.closest('.sidemenu-back') : target.closest('.sidemenu-link');

    if (!targetAnchor) {
      return null;
    }
    // livello dentro il quale si trova il link
    const thisLevel = targetAnchor.closest('.sidemenu-lev');
    // prossimo livello
    const nextLevelDepth = (parseInt(thisLevel.getAttribute('data-level'), 10) || 0) + 1;
    const timeout = e.type === 'mouseover' ? 400 : 0;

    if (targetAnchor.matches('[data-next-level]') || targetAnchor.matches('[data-prev-level]')) {
      e.preventDefault();
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      // tolgo is-active ai link del livello corrente
      const thisLevelActives = thisLevel.querySelectorAll('.is-active');
      if (thisLevelActives && thisLevelActives.length > 0) {
        [...thisLevelActives].forEach((el) => {
          el.classList.remove('is-active');
          el.setAttribute('aria-expanded', false);
        });
      }

      // chiudo TUTTI i cassetti successivi al livello corrente
      for (let i = nextLevelDepth; i <= sidemenuMaxLevel; i += 1) {
        const currentLevelLevs = document.querySelectorAll(`[data-level='${i}'${']'}`);
        if (currentLevelLevs && currentLevelLevs.length > 0) {
          [...currentLevelLevs].forEach((el) => {
            el.classList.remove('is-active');
            el.setAttribute('aria-expanded', false);
          });
        }
      }

      if (targetAnchor.matches('[data-next-level]') || targetAnchor.matches('[data-prev-level]')) {
        /* 
          Se è stato premuto il pulsante back: 

          - prendo il lev corrente e trovo il suo id
          - prendo il link corrispondente all'id del lev (sarebbe l'ID del link nella voce precedente)
          - prendo il lev in cui sta il link trovato

          Se è stato premuto un link: 
          - trovo l'ID del link interessato
          - trovo il LEV che deve aprirsi in base all'ID
        
        */

        const targetLev = backButton
          ? document.querySelector(`#link-${targetAnchor.closest('.sidemenu-lev').id.split('lev-').pop()}`).closest('.sidemenu-lev')
          : document.querySelector(`#lev-${targetAnchor.id.split('link-').pop()}`);

        if (targetLev && (window.innerWidth >= 1024 || e.type !== 'mouseover')) {
          if (targetAnchor.matches('[data-prev-level]')) {
            // chiudo il livello corrente e resetto i suoi link attivi
            thisLevel.classList.remove('is-active');
            thisLevel.setAttribute('aria-expanded', false);
            const targetLevActives = targetLev.querySelectorAll('.is-active');
            if (targetLevActives && targetLevActives.length > 0) {
              [...targetLevActives].forEach((el) => {
                el.classList.remove('is-active');
                el.setAttribute('aria-expanded', false);
              });
            }
          }

          // Imposto lo stato attivo sul link corrente
          targetAnchor.classList.add('is-active');
          targetAnchor.setAttribute('aria-expanded', true);

          // Apro il lev associato al link
          targetLev.classList.add('is-active');

          // Do il focus nel sottomenu
          const hElement = targetLev.querySelectorAll('h2, h3, h4, h5');
          if (hElement[0]) {
            const hlink = hElement[0].closest('a');
            if (hlink) {
              hlink.focus();
            } else {
              hElement[0].focus();
            }
          }
        }
      }
    }, timeout);
  };

  const handleKeyDownLevInitial = (e) => {
    if (e.key === 'Escape') {
      document.body.classList.remove('is-sidemenu-in');
      const hamburgerContainer = document.getElementById('header-hamburger');
      if (hamburgerContainer) {
        const button = hamburgerContainer.querySelector('button');
        if (button) {
          button.focus();
        }
      }
    }
  };

  const navigateToPreviousLevel = (target) => {
    target.classList.toggle('is-active');
    const targetId = target.id;
    let newId;
    if (targetId?.indexOf('lev') === 0) {
      newId = targetId.replace('lev', 'link');
    } else if (targetId?.indexOf('torna') === 0) {
      newId = targetId.replace('torna', 'link');
    }
    const element = document.getElementById(newId);
    if (element) {
      element.focus();
    }
  };

  const handleKeyDownLev = (e) => {
    const { target } = e;
    const submenu = target.closest('.sidemenu-lev');
    switch (e.type) {
      case 'keydown':
        // quando l'utente clicca Esc chiudo il sottomenu
        if (e.key === 'Escape') {
          navigateToPreviousLevel(submenu);
        } else if (e.key === 'Tab') {
          modalTrap(submenu, e);
        }
        break;
      default:
        break;
    }
  };

  const handleButtonTorna = (e) => {
    const { target } = e;
    const submenu = target.closest('.sidemenu-lev');
    navigateToPreviousLevel(submenu);
  };

  React.useEffect(() => {
    const items = document.querySelectorAll('.sidemenu-link, .sidemenu-action, .sidemenu-list-sep');
    const levInitial = document.getElementById('lev-initial');
    const levels = document.querySelectorAll('.sidemenu-lev');
    const buttonsTorna = document.querySelectorAll('.sidemenu-goback-link');

    /* gestione tab ed esc sul primo livello */
    levInitial.addEventListener('keydown', handleKeyDownLevInitial);

    /* gestione tab ed esc su livelli successivi */
    levels.forEach((lev) => {
      lev.addEventListener('keydown', handleKeyDownLev);
    });

    /* gestione click button torna */
    buttonsTorna.forEach((btn) => {
      btn.addEventListener('click', handleButtonTorna);
    });

    [...items].forEach((item) => {
      item.addEventListener('mouseover', handleEvent);
      item.addEventListener('touchend', handleEvent);
      item.addEventListener('click', handleEvent);
    });

    return () => {
      levInitial.removeEventListener('keydown', handleKeyDownLevInitial);
      levels.forEach((lev) => {
        lev.addEventListener('keydown', handleKeyDownLev);
      });
      buttonsTorna.forEach((btn) => {
        btn.addEventListener('click', handleButtonTorna);
      });
      [...items].forEach((item) => {
        item.removeEventListener('mouseover', handleEvent);
        item.removeEventListener('touchend', handleEvent);
        item.removeEventListener('click', handleEvent);
      });
    };
  });

  return null;
};

export { SidemenuBackdrop, SidemenuCloseButton, SidemenuVoce };
