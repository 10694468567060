import { parseCookies, setCookie } from 'nookies';

const cookies = parseCookies();


export function setDesktopCookie() {
  const expireTime = new Date(new Date().getTime() + 180 * 60 * 1000);
  setCookie({}, 'Webdesk', 'true', {
    expires: expireTime,
    path: '/',
    domain: '.ilsole24ore.com',
  });
}



export function getUsername(SSRcookies) {
  return SSRcookies && SSRcookies._u ? SSRcookies._u : cookies._u;
}

/* restituisce i dati dell'utente presi dal cookie del Sole */
export function getUserData(SSRcookies) {
  const userData = SSRcookies && SSRcookies.SoleUser ? SSRcookies.SoleUser : cookies.SoleUser;
  // const userData = 'friendlyname=UtenteTest per vedere la lunghezza del nickname&IsPremium=1&PremiumLevel=3&gender=M&lastlogin=2018-08-28T09:51:13';
  const cookie = {};
  if (userData) {
    try {
      const cookieValues = userData.split('&');
     /*  cookieValues.forEach((entry) => {
        const [key, value] = entry.split('=');
        cookie[key.replace(/(^\s+|\s+$)/g, '')] = value.replace(/(^\s+|\s+$)/g, '');
      }); */
      for (var i = 0; i < cookieValues.length; i++){
        const values = cookieValues[i].split('=');
        cookie[values[0].replace(/(^\s+|\s+$)/g, '')] = values[1].replace(/(^\s+|\s+$)/g, '');
      }
    } catch (err) {
      console.warn(err);
    }
  }
  return cookie;
}

/* verifica la presenza del cookie SoleAuth per sapere se si è loggati */
export function userIsLogged(SSRcookies) {
  const userLogged = SSRcookies && SSRcookies.SoleAuth ? SSRcookies.SoleAuth : cookies.SoleAuth;
  return typeof userLogged !== 'undefined';
}

export function userIsAuthorized() {
  const b64AccessCookie = cookies.m_pf;
  const accessCookie = b64AccessCookie ? JSON.parse(atob(b64AccessCookie)) : { access: 'false' };
  const access = JSON.parse(accessCookie.access);
  return access;
}

export function getMeteoCookie() {
  return cookies['meteo-online'];
}

export function acceptCookiePolicy() {
  return cookies.SoleCookieAccept === 'Y';
}
