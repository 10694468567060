/* funzioni common tra client e server. 
ATTENZIONE: non includere funzioni o costanti che richiamano funzioni o costanti di next!!! */

export function getWordsFirstLetter(words) {
  if (!words) {
    return '';
  }
  const matches = words.match(/\b(\w)/g);
  return matches.join('');
}

export const mapper = {
  mercati: 'BOL/BOL',
  plus24: 'OLC/24PLUS',
  realestate: 'OLC/realestate',
  quotidiano: 'QOL/QOL',
  qfisco: 'OLC/quotidiano_fisco',
  qlavoro: 'OLC/olc_quotidianolavoro',
  qdiritto: 'OLC/quotidiano_diritto',
  qcondominio: 'OLC/quotidianocondominio',
};

export function mapProduct(product) {
  return mapper[product];
};
