import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';
import ModalLockSole from '@client/ClientEvents/sole24ore/ModalLock.jsx';
import ModalLockPlus from '@client/ClientEvents/plus24/ModalLock.jsx';

const ModalLock = () => {
  const config = useContext(AppContext);
  const { website } = config;
  switch (website) {
    case 'sole24ore':
      return <ModalLockSole/>;
    case 'plus24':
      return <ModalLockPlus  />;
    default:
      return <ModalLockSole />;
  }
};

export default ModalLock;
