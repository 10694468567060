import React, { createContext, useState, useEffect } from 'react';

const AppContext = createContext({});

function getCurrentSizes() {
  if (typeof document !== 'undefined') {
    const { documentElement } = document;
    const body = document.getElementsByTagName('body')[0];
    const width = window.innerWidth || documentElement.clientWidth || body.clientWidth;
    const height = window.innerHeight || documentElement.clientHeight || body.clientHeight;
    return { width, height };
  }
  return {};
}

function getCurrentOrientation(orientation) {
  const { width, height } = getCurrentSizes();
  //console.log(width, height, width > height, width > height ? 'landscape' : 'portrait' )
  if (width || height) {
    const currentOrientation = width > height ? 'landscape' : 'portrait';
    return currentOrientation;
  }
  return orientation;
}

function checkOrientation(orientation) {
  return orientation === getCurrentOrientation(orientation);
}

export function AppContextComponent({ children, config }) {
  const { device } = config;
  const defaultOrientation = getCurrentOrientation('portrait');

  const [geometry, setGeometry] = useState({ orientation: defaultOrientation});

  useEffect(() => {
    const onResize = () => {
      const { orientation } = geometry;
      const currentOrientation = getCurrentOrientation(orientation);
      if (currentOrientation !== orientation) {
        // console.log('ctx', [device, orientation], [tempDevice, tempOrientation]);
        setGeometry({
          orientation: currentOrientation !== orientation ? currentOrientation : orientation,
        });
      }
    };
    // console.log('ctx', appData, addProps);
    if (!checkOrientation(geometry.orientation)) {
      onResize();
    }

    window.addEventListener('resize', onResize, { passive: true });
    return () => {
      window.removeEventListener('resize', onResize, { passive: true });
    };
  }, [geometry, config, device]);

  return <AppContext.Provider value={{ ...geometry, ...config }}>{children}</AppContext.Provider>
}

export default AppContext;
