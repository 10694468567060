import React from 'react';
import PropTypes from 'prop-types';

const HamburgerButton = ({ type, newDesign, noLabel }) => {
  const handler = (e) => {
    e.preventDefault();
    document.body.classList.toggle('is-sidemenu-in');
    const levInitial = document.getElementById('lev-initial');
    levInitial.focus();
  };
  const buttonClassName = newDesign ? 'hicon-link' : `hlink hlink--icon${type === 'mobile' ? '-big' : ''} no-border no-outline ${type === 'mobile' ? 'd-md-none' : ''}`;
  const iconClassName = newDesign ? 'icon icon-menu' : `icon ${type === 'mobile' ? 'icon--big' : ''} icon-menu`;
  return (
    <button type="button" className={buttonClassName} onClick={handler} aria-label="Naviga" tabIndex="0" aria-haspopup="dialog">
      {newDesign && !noLabel && (
        <span className="hicon-label" aria-hidden="true">
          Naviga
        </span>
      )}
      <span className={iconClassName} />
    </button>
  );
};

HamburgerButton.defaultProps = {
  type: '',
  newDesign: false,
  noLabel: false,
};

HamburgerButton.propTypes = {
  type: PropTypes.string,
  newDesign: PropTypes.bool,
  noLabel: PropTypes.bool,

};

export default HamburgerButton;
