import React from 'react';
import { cleanup, plus24Domain } from '@client/ClientEvents/plus24/utils.jsx';

const SearchButton = () => {
  const handler = (e) => {
    const { body } = document;
    cleanup('search-open');
    body.classList.toggle('is-search-open');
  };
  return (
    <button type="button" className="ltool" onClick={handler} aria-label="Cerca nel sito" tabIndex="0">
      <span className="icon icon-search" />
    </button>
  );
};

export const CloseSearchButton = () => {
  const handler = (e) => {
    const { body } = document;
    body.classList.remove('is-search-open');
  };
  return (
    <button type="button" className="ltool" onClick={handler} aria-label="Chiudi la modale" tabIndex="0">
      <span className="icon icon-close" />
    </button>
  );
};

export const GoSearchButton = () => {
  const [inputValue, setInputValue] = React.useState('');
  const handler = () => {
    document.location = `${plus24Domain}/ricerca/${inputValue}`;
  };
  return (
    <React.Fragment>
      <input className="menu-search-input" type="text" name="" placeholder="Cerca" onChange={(e) => setInputValue(e.target.value)} />
      <button className="menu-search-btn" type="button" name="button" onClick={handler} aria-label="avvia la ricerca" tabIndex="0">
        <span className="icon icon-search" />
      </button>
    </React.Fragment>
  );
};

export default SearchButton;
