import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ModalLogin, { modalVisibility } from '@client/ClientEvents/plus24/Modal.jsx';
import UserButton, { UserButtonAnon, LogoutButtonHeader } from '@client/ClientEvents/plus24/UserButton.jsx';
import SearchButton, { CloseSearchButton, GoSearchButton } from '@client/ClientEvents/plus24/SearchButton.jsx';
import HamburgerButton from '@client/ClientEvents/plus24/HamburgerButton.jsx';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';
import { cleanup } from '@client/ClientEvents/plus24/utils.jsx';
import { appendBackdrop } from '@client/ClientEvents/Common/index.jsx';

const HeaderEvents = () => {
  const config = useContext(AppContext);
  const { section, logged, friendlyname, errMsg, isLocked } = config;

  // la seconda parte della condizione è per evitare problemi di layout nel caso 'homepage' non sia nella lista di testate
  // disponibili nella config lato server
  if (section === 'homepage' && document.querySelector('.toph') != null) document.body.classList.add('is-home');
  useEffect(() => {
    const onScroll = (e) => {
      const offset = document.body.getBoundingClientRect();
      const offsetTop = offset.y || offset.top;
      cleanup('is-scrolling');
      if (-offsetTop - 15 > 0) {
        document.body.classList.add('is-scrolled');
        document.querySelector('.sticky-header').setAttribute('style', 'opacity: 1; -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); box-shadow: 0 2px 0 0 rgba(0,0,0,.15);');
      } else {
        document.body.classList.remove('is-scrolled');
        document.querySelector('.sticky-header').setAttribute('style', '');
      }
    };
    window.addEventListener('scroll', onScroll);
    // return () => { window.removeEventListener("scroll", onScroll); }
    appendBackdrop();
  });

  useEffect(() => {
    // se avevo dei parametri di errore dal du in query string mostro direttamente la modale di login perché significa che c'è stato un problema.
    if (errMsg != '' && !isLocked && !logged) modalVisibility('show', 'block');
  }, []);

  return (
    <React.Fragment>
      <MainButtons />
      {(!logged && document.querySelector('#user_button_anon') && <AnonButtons />) || (logged && document.querySelector('#user_button_logged') && <LoggedInButtons friendlyname={friendlyname} />)}
    </React.Fragment>
  );
};

const MainButtons = () => (
  <React.Fragment>
    {ReactDOM.createPortal(<HamburgerButton />, document.querySelector('#hamburger_button'))}
    {ReactDOM.createPortal(<HamburgerButton />, document.querySelector('#hamburger_button-sticky'))}
    {ReactDOM.createPortal(<SearchButton />, document.querySelector('#search_button'))}
    {ReactDOM.createPortal(<SearchButton />, document.querySelector('#search_button-sticky'))}
    {ReactDOM.createPortal(<CloseSearchButton />, document.querySelector('#closesearch_button'))}
    {ReactDOM.createPortal(<GoSearchButton />, document.querySelector('#gosearch_button'))}
    {ReactDOM.createPortal(<GoSearchButton />, document.querySelector('#gosearch_button_hamburger'))}
  </React.Fragment>
);

const AnonButtons = () => (
  <React.Fragment>
    {ReactDOM.createPortal(<UserButtonAnon />, document.querySelector('#user_button_anon'))}
    {ReactDOM.createPortal(<UserButtonAnon />, document.querySelector('#user_button_anon-sticky'))}
    {ReactDOM.createPortal(<ModalLogin />, document.querySelector('#modal_login_placeholder'))}
  </React.Fragment>
);

const LoggedInButtons = ({ friendlyname }) => (
  <React.Fragment>
    {ReactDOM.createPortal(<UserButton friendlyname={friendlyname} />, document.querySelector('#user_button_logged'))}
    {ReactDOM.createPortal(<UserButton friendlyname={friendlyname} />, document.querySelector('#user_button_logged-sticky'))}
    {ReactDOM.createPortal(<LogoutButtonHeader />, document.querySelector('#user_button_logout_header'))}
    {ReactDOM.createPortal(<LogoutButtonHeader />, document.querySelector('#user_button_logout_header-sticky'))}
  </React.Fragment>
);

LoggedInButtons.propTypes = {
  friendlyname: PropTypes.string.isRequired,
}

export default HeaderEvents;
